async function askForPermission() {
  console.log("ask for permission");
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications!");
    throw new Error("This browser does not support notifications!");
  }

  const status = await Notification.requestPermission();
  console.log("Notification permission status:", status);
  return status === "granted";
}

function urlB64ToUint8Array() {
  // This can be obtained from Firebase project console
  const applicationServerPublicKey =
    process.env.FIREBASE_APPLICATION_SERVER_PUBLIC_KEY;
  // "BP_X274t62HZp3nhoshOFKrXdwBL_ajzx1sCFXr0W4h2VCyJXJ1_72HN097K_bhubC2npe_HkFpN0VaIsjf4cCs";

  const padding = "=".repeat((4 - (applicationServerPublicKey.length % 4)) % 4);
  const base64 = (applicationServerPublicKey + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Subscribe user is actually subscribing push registration
async function subscribeUser(swRegistration) {
  console.log("-----------subscribe user--------------");
  const applicationServerKey = urlB64ToUint8Array();

  try {
    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey,
    });

    console.log("User has subscribed successfully");
    return subscription;
  } catch (subscribeError) {
    if (Notification.permission === "denied") {
      console.warn("Permission for notifications was denied");
    } else {
      console.error("Failed to subscribe the user: ", subscribeError);
    }

    return null;
  }
}

async function getSubscription() {
  console.log("try to get subscription");

  if(!isOnAboutPage()) return null;

  try {
    const swRegistration = await navigator.serviceWorker.ready;
    let pushSubscription = await swRegistration.pushManager.getSubscription();
    console.log("pushSubscription: ", pushSubscription);

    // if not found from pushManager, then we subscribe the user right now
    if (!pushSubscription) {
      pushSubscription = await subscribeUser(swRegistration);
    }

    pushSubscription = pushSubscription.toJSON();
    document.getElementById("subkey").textContent = pushSubscription.keys.auth;
    return pushSubscription;
  } catch (error) {
    console.log("getSubscription() error: ", error);
    return null;
  }
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(email);
  console.log("validateEmail(): ", valid);
  return valid;
}

async function updateSubscriptionOnServer(pushSubscriptionObject) {
  // Only stringified pushSubscription has the keys.auth property.
  console.log(
    "--------------SaveReg() stringified--------------",
    JSON.stringify(pushSubscriptionObject)
  );

  let url = "";
  try {
    url = `${process.env.NETLIFY_FUNCTION_URL_BASE}${process.env.NETLIFY_FUNCTION_UPDATE_SUBSCRIPTION}`;

    console.log("--------updateSub() url--------", url);

    await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, *cors, cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(pushSubscriptionObject), // body data type must match "Content-Type" header
    });

    return true;
  } catch (ex) {
    console.log("saveReg() catch block:", ex);
    return false;
  }
}

async function updateSubscription() {
  try {
    const allowed = await askForPermission();
    if (!allowed) return;

    let subscription = await getSubscription();
    if (!subscription) return;

    // email
    const email = getEmail();
    if (!email || !validateEmail(email)) {
      alert("huh...so how are you going to receive notifications?");
      return;
    }

    // ip
    let ipObject = await getIP();

    let extra = {};
    if (ipObject) {
      ipObject.email = email;
      extra = ipObject;
    } else {
      extra.email = email;
    }

    console.log("extra: ", extra);

    // var newSubscription = Object.assign({}, subscription, {extra});
    // const newSubscription = {
    //   ...subscription,
    //   extra
    // };
    let newSubscription = subscription.toJSON();
    newSubscription.extra = extra;
    console.log("new subscription1: ", newSubscription);
    console.log(
      "new subscription1 stringified: ",
      JSON.stringify(newSubscription)
    );

    const successful = await updateSubscriptionOnServer(newSubscription);

    if (successful) alert("you have successfully subscribed to the DC monitor");
    else alert("shit happens, try it later");
  } catch (err) {
    console.log("updateSubscription() failed: ", err);
  }
}

function isOnAboutPage() {
  
  const pathname = window.location.pathname;
  console.log("the current location is: ", pathname);
  
  if (pathname.indexOf("/about") >= 0) return true;

  return false;
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

function getEmail() {
  console.log("getEmail()");
  let emailAddress = null;

  const onAboutPage = isOnAboutPage();
  console.log(`we are ${onAboutPage ? "" : "not"} on about page`);

  if (onAboutPage) {
    console.log("waiting for html rendering...");
    sleep(1000);
    emailAddress = document.getElementById("email").value;
  }

  if (emailAddress) {
    localStorage.setItem("dc_email", emailAddress);
    return emailAddress;
  }

  if (localStorage) {
    emailAddress = localStorage.getItem("dc_email");
  }

  console.log("getEmail(): ", emailAddress);
  return emailAddress;
}



// No need to save IP object into localStorage as users could use their mobile data or WiFi network.
// So it's better to get IP every time.
async function getIP() {
  console.log("getIP()");
  const response = await fetch("https://ipapi.co/json/", {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
  });

  if (response.status >= 200 && response.status < 300) {
    const ipObject = await response.json();
    const result = {
      city: ipObject.city,
      country: ipObject.country_name,
      ip: ipObject.ip,
      lat: ipObject.latitude,
      lng: ipObject.longitude,
      org: ipObject.org,
      postal: ipObject.postal,
      region: ipObject.region,
    };

    console.log("getIP(): ", result);
    return result;
  } else {
    console.log("getIP() failed");
    return null;
  }
}

const loadData = function() {
  console.log("loading data....");
  const emailAddress = getEmail();

  if (emailAddress) { 
    if (isOnAboutPage()) {
      document.getElementById("email").value = emailAddress;
      getSubscription();
    }
  }

};

module.exports = {
  getEmail,
  getIP,
  getSubscription,
  askForPermission,
  updateSubscription,
  loadData,
  urlB64ToUint8Array,
  isOnAboutPage
};
