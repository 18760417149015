import { getEmail, getIP, loadData, urlB64ToUint8Array } from "./src/tools";

if (!("Notification" in window)) {
  console.log("This browser does not support notifications!");
  throw new Error("This browser does not support notifications!");
}
// TODO 2.2 - request permission to show notifications
Notification.requestPermission((status) => {
  console.log("Notification permission status:", status);
});

async function saveRegistration(pushSubscriptionObject) {
  // Only stringified pushSubscription has the keys.auth property.
  console.log(
    "--------------SaveReg() stringified--------------",
    JSON.stringify(pushSubscriptionObject)
  );

  const url = `${process.env.NETLIFY_FUNCTION_URL_BASE}${process.env.NETLIFY_FUNCTION_ADD_SUBSCRIPTION}`; //"http://localhost:34567/addSubscription"
  console.log("--------addSub() url--------", url);

  const email = getEmail();
  const ip = await getIP();

  let sub = pushSubscriptionObject.toJSON();
  sub.extra = {
    ...ip,
    email
  };

  console.log("gatsby-browser.saveRegistration(): ", sub);

  await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, *cors, cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(sub), // body data type must match "Content-Type" header
  });

  return true;
}

// Subscribe user is actually subscribing push registration
async function subscribeUser(swRegistration, pushSubscription) {
  // If the client/user has already subscribed, then we just save the pushSubscription.
  if (pushSubscription) {
    return await saveRegistration(pushSubscription);
  }

  console.log("-----------subscribe user--------------");
  const applicationServerKey = urlB64ToUint8Array();

  try {
    const subscription = await swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey,
    });

    console.log("User has subscribed successfully");
    return saveRegistration(subscription);
  } catch (subscribeError) {
    if (Notification.permission === "denied") {
      console.warn("Permission for notifications was denied");
    } else {
      console.error("Failed to subscribe the user: ", subscribeError);
    }

    return false;
  }
}

async function bootStrap() {
  try {
    const swRegistration = await navigator.serviceWorker.ready;
    const pushSubscription = await swRegistration.pushManager.getSubscription();
    console.log("pushSubscription: ", pushSubscription);
    await subscribeUser(swRegistration, pushSubscription);
  } catch (serviceWorkerError) {
    console.error("Service Worker Error", serviceWorkerError);
  }
}

export const onClientEntry = (_, pluginOptions) => {
  if ("serviceWorker" in navigator) {
    console.log("---------Service Worker and Push is supported---------");
    window.addEventListener("load", bootStrap);
  } else {
    console.warn("Push messaging is not supported");
  }
  return false; // false makes sure the Gatsby uses gatsby-browser.js
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('new pathname', location.pathname)
  console.log('old pathname', prevLocation ? prevLocation.pathname : null)

  const pathname = location.pathname;
  console.log("the current location is: ", pathname);

  if (pathname.indexOf("/about") >=0 ) {
    console.log("loadData()....");
    loadData();
  }
};